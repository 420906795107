import "office-ui-fabric-react/dist/css/fabric.min.css";
//import './styles.less';
import * as React from "react";
import * as ReactDOM from "react-dom";
//import { useContext } from 'react';
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import App from "./components/AppDialogFn";
import { DialogType, LimaTitleTaskHist } from "./limaDialogCommon";

/* global AppCpntainer, Component, document, Office, module, require */

initializeIcons();

let isOfficeInitialized = false;
const title = LimaTitleTaskHist;
const dialogType = DialogType.taskhistory;

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component title={title} isOfficeInitialized={isOfficeInitialized} dialogType={dialogType} />
    </AppContainer>,
    document.getElementById("container")
  );
};

/* Initial render showing a progress bar */
render(App);

if ((module as any).hot) {
  (module as any).hot.accept("./components/AppDialogFn", () => {
    const NextApp = require("./components/AppDialogFn").default;
    render(NextApp);
  });
}

Office.onReady(info => {
  // If needed, Office.js is ready to be called

  (window as any).Promise = OfficeExtension.Promise;

  if (info.host === Office.HostType.Excel) {
    // Do Excel-specific initialization (for example, make add-in task pane's
    // appearance compatible with Excel "green").
  }
  if (info.platform === Office.PlatformType.PC) {
    // Make minor layout changes in the task pane.
  }
  console.log(`Office.js is now ready in ${info.host} on ${info.platform}`);
  isOfficeInitialized = true;

  render(App);
});
